
// Libraries
import * as React from 'react'

// Services
import ApiService from '../../../services/api'

// Components
import PrivateRoute from '../../../components/privateRoute'
import LayoutAdmin from '../../../components/layoutAdmin'
import Seo from '../../../components/seo'
import Button from '../../../components/button'
import {faFileExport} from '@fortawesome/pro-solid-svg-icons'
import AdminTableHeader from '../../../components/admin/adminTableHeader'
import AdminEventViewRow from "../../../components/admin/events/adminEventViewRow";
import NotificationContext from "../../../contexts/notification";

class AdminCsvExportsIndexPage extends React.Component {
	static contextType = NotificationContext

	render() {
		return <PrivateRoute admin>
			<LayoutAdmin className="page--admin-table nav-blue-half">
				<Seo title="Blog Management" />
				<div className="admin-table__wrap">
					<div className="admin-table">
						<AdminTableHeader titleText='CSV Exports' titleIcon={faFileExport} />
						<div className="admin-view admin-view--csv-exports">
							<p>CSV exports happen in the background, and you will be sent the export via email when it is complete.</p>
							<p>Depending on the amount of data in your export, it might take a while for the export to reach you.</p>
							<div className="admin-view__view">
								<div className="admin-view__view-group">
									<AdminEventViewRow label="Carers" value={<ul>
										<li><Button onClick={() => this.handleStartExport('users/export/carers-all', 'carers-all')} colorEndeavour hollow>All</Button></li>
										<li><Button onClick={() => this.handleStartExport('users/export/carers-by-region', 'carers-by-region')} colorEndeavour hollow>By region</Button></li>
										<li><Button onClick={() => this.handleStartExport('users/export/carers-by-organisation', 'carers-by-organisation')} colorEndeavour hollow>By organisation</Button></li>
										<li><Button onClick={() => this.handleStartExport('users/export/carers-by-trust', 'carers-by-trust')} colorEndeavour hollow>By trust</Button></li>
										<li><Button onClick={() => this.handleStartExport('users/export/abandoned-registrations', 'abandoned-registrations')} colorEndeavour hollow>Abandoned registrations</Button></li>
									</ul>} />
									<AdminEventViewRow label="Events" value={<ul>
										<li><Button onClick={() => this.handleStartExport('events/export/all', 'events-all')} colorEndeavour hollow>All</Button></li>
										<li><Button onClick={() => this.handleStartExport('events/export/by-region', 'events-by-region')} colorEndeavour hollow>By region</Button></li>
										<li><Button onClick={() => this.handleStartExport('events/export/by-venue', 'events-by-venue')} colorEndeavour hollow>By venue</Button></li>
										<li><Button onClick={() => this.handleStartExport('events/export/by-type', 'events-by-type')} colorEndeavour hollow>By type</Button></li>
									</ul>} />
									<AdminEventViewRow label="Ballots" value={<ul>
										<li><Button onClick={() => this.handleStartExport('ballots/export/all', 'ballots-all')} colorEndeavour hollow>All</Button></li>
										<li><Button onClick={() => this.handleStartExport('ballots/export/by-month', 'ballots-by-month')} colorEndeavour hollow>By month</Button></li>
									</ul>} />
									<AdminEventViewRow label="Venues" value={<ul>
										<li><Button onClick={() => this.handleStartExport('venues/export/all', 'venues-all')} colorEndeavour hollow>All</Button></li>
										<li><Button onClick={() => this.handleStartExport('venues/export/by-region', 'venues-by-region')} colorEndeavour hollow>By region</Button></li>
									</ul>} />
									<AdminEventViewRow label="Organisations" value={<ul>
										<li><Button onClick={() => this.handleStartExport('organisations/export/all', 'organisations-all')} colorEndeavour hollow>All</Button></li>
										<li><Button onClick={() => this.handleStartExport('organisations/export/by-region', 'organisations-by-region')} colorEndeavour hollow>By region</Button></li>
									</ul>} />
									<AdminEventViewRow label="Trusts" value={<ul>
										<li><Button onClick={() => this.handleStartExport('trusts/export/all', 'trusts-all')} colorEndeavour hollow>All</Button></li>
									</ul>} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</LayoutAdmin>
		</PrivateRoute>
	}

	handleStartExport = async (url, filename) => {
		const apiService = new ApiService()
		const {addNotification} = this.context

		return apiService.get(url, {}, {})
			.then( (response) => {
				addNotification('Your export has been queued. You will receive an email when it is ready to download.', 'success')
			}).catch(err => {
				addNotification('An error occurred when queueing your export.', 'error');
			})
	}
}

export default AdminCsvExportsIndexPage
